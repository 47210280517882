import React from 'react'
import { Link } from 'gatsby'
import { ContentfulCard } from '../../../types/graphql-types'

interface Props {
  ctaPhrase: string
}

const Cards: React.FC<
  Props & Pick<ContentfulCard, 'slug' | 'headline' | 'title' | 'featuredImage'>
> = ({ slug, headline, title, featuredImage, ctaPhrase }) => (
  <>
    <img
      className="w-96 rounded-t-lg border-b-12 border-b-bby-blue-700"
      src={featuredImage?.file?.url as string}
      alt=""
    />
    <h3 className="mb-4 p-4 text-lg leading-tight">{title}</h3>
    <p className="absolute bottom-4 mb-0">
      <span className="icon-arrow-right text-md ml-4 mr-2 inline-flex h-5 w-5 items-center justify-center rounded-full bg-bby-blue-700 text-white no-underline hover:no-underline"></span>
      <Link to={slug as string}>
        {ctaPhrase}
        <span className="sr-only">about {headline}</span>
      </Link>
    </p>
  </>
)

export default Cards
