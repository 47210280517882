import React from 'react'

const ProductBuyNowForm = (props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined
  productCategory: string
  productName: string
  price: string
  sku: string
  productId: string
  productTypeId: string
  className?: string
  packageID?: string
  location?: string
}): JSX.Element => {
  return (
    <form
      action={`/cart/start${props.packageID || ''}`}
      data-link-type="cart"
      data-product-category={props.productCategory}
      data-product-name={props.productName}
      data-product-price={props.price}
      data-product-sku={props.sku}
      data-button-location={props.location}
      method="post"
      className={`block md:inline-block ${props.className || ''}`}
    >
      {props.productId && (
        <input
          name="ProductId"
          type="hidden"
          value={(props.productId as string) || ''}
        />
      )}
      <input
        name="DeviceTypeId"
        type="hidden"
        value={(props.productTypeId as string) || ''}
      />
      {props.children}
    </form>
  )
}

export default ProductBuyNowForm
