import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import Cards from '../Cards'
import {
  ContentfulCard,
  ContentfulCards,
} from '../../../../types/graphql-types'

interface Props {
  data: {
    explore?: ContentfulCards
    resourceArticle?: {
      related?: Array<{
        id: string
        title: string
        externalName: string
        cards: ContentfulCard[]
      }>
    }
    featured?: ContentfulCards
  }
  ariaLabel: string
  instructionsId: string
  ctaPhrase: string
  alias: string
  touchId: string
  hoverFocusId: string
  hoverId: string
  focusId: string
}

const ContentSlider = styled.div`
  .slider:focus {
    ${tw`outline-black`}
  }
  li {
    ${tw`pb-4 relative shadow-lively`}
    &:first-of-type {
      ${tw`ml-4 md:ml-0`}
    }
    @media (max-width: 767px) {
      min-width: 270px;
    }
    @media (min-width: 768px) {
      width: 240px;
    }
    @media (min-width: 1024px) {
      width: 310px;
    }
    @media (min-width: 1280px) {
      width: 370px;
    }
    h3 {
      ${tw`mb-10`}
    }
    a {
      ${tw`left-10 whitespace-nowrap`}
    }
  }
  .instructions {
    ${tw`md:hidden`}
  }
  .instructions p {
    ${tw`p-4 mb-0 text-center bg-lively-gray text-white`}
  }
  [id^='focus'],
  [id^='hover'],
  [id^='hover-and-focus'],
  [id^='touch'] {
    ${tw`hidden`}
  }

  .slider:focus + .instructions [id^='focus'],
  .slider:hover + .instructions [id^='hover'] {
    ${tw`block lg:hidden`}
  }

  .slider:hover + .instructions [id^='hover'] + [id^='focus'] {
    ${tw`hidden`}
  }

  .slider:hover:focus + .instructions [id^='hover-and-focus'] {
    ${tw`block lg:hidden`}
  }

  .slider:hover:focus + .instructions [id^='hover-and-focus'] ~ * {
    ${tw`hidden`}
  }

  .touch .instructions p {
    display: none !important;
  }

  .touch .instructions [id^='touch'] {
    display: block !important;
  }
`
const SliderCards: React.FC<Props> = ({
  data,
  ariaLabel,
  instructionsId,
  ctaPhrase,
  alias,
  touchId,
  hoverFocusId,
  hoverId,
  focusId,
}: Props) => {
  useEffect(() => {
    // must only be run after page is loaded otherwise window not available
    // Listen for touch events
    window.addEventListener(
      'touchstart',
      function touched() {
        document.body.classList.add('touch')
        window.removeEventListener('touchstart', touched, false)
      },
      false
    )
  }, [])

  return (
    <ContentSlider>
      <div
        className={`slider overflow-x-auto overflow-y-hidden xl:container ${alias}`}
        role="region"
        aria-label={ariaLabel}
        aria-describedby={instructionsId}
        tabIndex={0}
      >
        {alias === 'featured' && (
          <ul className="flex flex-row flex-nowrap justify-start md:place-content-center md:justify-around md:pb-16">
            {(data.featured?.cards as ContentfulCard[]).map((card, index) => {
              return (
                <li
                  key={index}
                  className="relative mr-4 flex-initial rounded-lg bg-white md:mr-0 md:flex-none"
                >
                  <Cards
                    slug={card.slug}
                    title={card.title}
                    featuredImage={card.featuredImage}
                    ctaPhrase={ctaPhrase}
                    headline={card.headline}
                  />
                </li>
              )
            })}
          </ul>
        )}
        {alias === 'related' &&
          data.resourceArticle?.related?.map(list => {
            return (
              <ul
                key={list.id}
                className="flex flex-row flex-nowrap justify-start md:place-content-center md:justify-around md:pb-16"
              >
                {(list.cards as ContentfulCard[]).map((card, id) => {
                  return (
                    <li
                      key={id}
                      className="relative mr-4 flex-initial rounded-lg bg-white md:mr-0 md:flex-none"
                    >
                      <Cards
                        slug={card.slug}
                        title={card.title}
                        featuredImage={card.featuredImage}
                        ctaPhrase={ctaPhrase}
                      />
                    </li>
                  )
                })}
              </ul>
            )
          })}
        {alias === 'explore' && (
          <ul className="flex flex-row flex-nowrap place-content-start justify-start md:place-content-center md:justify-around md:pb-16">
            {(data.explore?.cards as ContentfulCard[]).map((card, index) => {
              return (
                <li
                  key={index}
                  className="relative mr-4 flex-initial rounded-lg bg-white md:mr-0 md:flex-none"
                >
                  <Cards
                    slug={card.slug}
                    title={card.title}
                    featuredImage={card.featuredImage}
                    ctaPhrase={ctaPhrase}
                    headline={card.headline}
                  />
                </li>
              )
            })}
          </ul>
        )}
      </div>
      <div className="instructions" id={instructionsId}>
        <p id={touchId}>swipe for more</p>
        <p id={hoverFocusId}>scroll or use your arrow keys for more</p>
        <p id={hoverId}>scroll for more</p>
        <p id={focusId}>use your arrow keys for more</p>
      </div>
    </ContentSlider>
  )
}

export default SliderCards
